// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asia-americas-ride-hailing-report-js": () => import("./../../../src/pages/asia-americas-ride-hailing-report.js" /* webpackChunkName: "component---src-pages-asia-americas-ride-hailing-report-js" */),
  "component---src-pages-asia-food-delivery-report-js": () => import("./../../../src/pages/asia-food-delivery-report.js" /* webpackChunkName: "component---src-pages-asia-food-delivery-report-js" */),
  "component---src-pages-digital-economy-annual-report-2024-js": () => import("./../../../src/pages/digital-economy-annual-report-2024.js" /* webpackChunkName: "component---src-pages-digital-economy-annual-report-2024-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/termsOfUse.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

